import { semesters } from "constants/data/semesters";
import {
  defaultPage,
  defaultSearchTerm,
  defaultShowPerPage,
} from "constants/jsons/defaultTableHelper.json";
import { getBranchSessionClassWiseExamListFromExamSchedule } from "network/apiExamList/apiExamList";
import { getAssignSubjectForFilter } from "network/assignSubject/apiAssignSubject";
import { getAllBranch } from "network/branch/loadBranch";
import {
  branchWiseSession,
  classWiseSection,
  sessionWiseClass,
} from "network/helpers/basedOnApis";
import { useEffect, useState } from "react";

const user = JSON.parse(localStorage.getItem("user"));
const currentSession = JSON.parse(localStorage.getItem("session"));

const useBranchSessionClassSectionExamList = (classes = []) => {
  const [branchId, setBranchId] = useState(null);
  const [branchName, setBranchName] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [sessionName, setSessionName] = useState("");
  const [classId, setClassId] = useState(null);
  const [className, setClassName] = useState("");
  const [classNumeric, setClassNumeric] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [sectionName, setSectionName] = useState("");
  const [examId, setExamId] = useState(null);
  const [examName, setExamName] = useState("");
  const [subjectId, setSubjectId] = useState(null);
  const [subjectName, setSubjectName] = useState("");
  const [semester, setSemester] = useState(null);
  const [semesterName, setSemesterName] = useState(null);
  // -----
  const [allBranch, setAllBranch] = useState([]);
  const [allSession, setAllSession] = useState([]);
  const [allClass, setAllClass] = useState([]);
  const [allSection, setAllSection] = useState([]);
  const [allExam, setAllExam] = useState([]);
  const [allSubject, setAllSubject] = useState([]);
  const [allSemester, setAllSemester] = useState([]);

  // Set default branch and session for non-superAdmin users
  useEffect(() => {
    if (user?.role !== "superAdmin") {
      setBranchId(user?.branch?._id);
      setBranchName(user?.branch?.branchName);
      setSessionName(currentSession?.name);
      setSessionId(currentSession?.id);
    }
  }, [user, currentSession]);

  // Fetch all branches
  useEffect(() => {
    async function loadBranch() {
      const data = await getAllBranch(
        defaultPage,
        defaultShowPerPage,
        defaultSearchTerm
      );
      setAllBranch(data?.data || []);
    }

    loadBranch();
  }, [defaultPage, defaultShowPerPage, defaultSearchTerm]);

  // Fetch sessions based on the selected branch
  useEffect(() => {
    async function branchToSession() {
      if (branchId) {
        const data = await branchWiseSession(branchId);
        setAllSession(data?.data || []);
      }
    }

    if (branchId) {
      branchToSession();
    } else {
      setAllSession([]);
    }
  }, [branchName, branchId]);

  // Fetch classes based on the selected session
  useEffect(() => {
    async function sessionToClass() {
      const data = await sessionWiseClass(sessionId);

      if (classes?.length) {
        const result = data?.data?.filter(
          (item) =>
            item.classNumeric >= classes[0] &&
            item.classNumeric <= classes[classes.length - 1]
        );
        setAllClass(result || []);
      } else {
        setAllClass(data?.data || []);
      }
    }
    if (sessionId) {
      sessionToClass();
    } else {
      setAllClass([]);
    }
  }, [branchName, branchId, sessionName, sessionId, classes]);

  // Fetch sections based on the selected class
  useEffect(() => {
    async function classToSection() {
      const data = await classWiseSection(classId);
      setAllSection(data?.data || []);
    }

    if (classId) {
      classToSection();
    } else {
      setAllSection([]);
    }
  }, [branchName, branchId, sessionName, sessionId, classId]);

  // Fetch exams based on branch, session, class, and section
  useEffect(() => {
    async function branchSessionClassSectionToExamList() {
      const scheduleListWithExam =
        await getBranchSessionClassWiseExamListFromExamSchedule(
          branchName,
          branchId,
          sessionName,
          sessionId,
          classId,
          sectionId
        );
      const allExamFromSchedule = scheduleListWithExam?.data?.map(
        (schedule) => schedule.exam
      );
      setAllExam(allExamFromSchedule || []);
    }

    if (
      branchName &&
      branchId &&
      sessionName &&
      sessionId &&
      classId &&
      sectionId
    ) {
      branchSessionClassSectionToExamList();
    } else {
      setAllExam([]);
    }
  }, [branchName, branchId, sessionName, sessionId, classId, sectionId]);

  // Fetch subjects based on branch, session, class, and section
  useEffect(() => {
    async function branchSessionToSubject() {
      const data = await getAssignSubjectForFilter(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        sectionId
      );
      setAllSubject(data);
    }

    if (
      branchName &&
      branchId &&
      sessionName &&
      sessionId &&
      classId &&
      sectionId
    ) {
      branchSessionToSubject();
    } else {
      setAllSubject([]);
    }
  }, [branchName, branchId, sessionName, sessionId, classId, sectionId]);

  useEffect(() => {
    async function setSemester() {
      const data = semesters.filter((semester) =>
        semester?.classes?.some((cls) => cls.numeric === classNumeric)
      );
      setAllSemester(data || []);
    }
    if (classNumeric) {
      setSemester();
    }
  }, [classNumeric]);

  return {
    branchId,
    setBranchId,
    branchName,
    setBranchName,
    sessionId,
    setSessionId,
    sessionName,
    setSessionName,
    classId,
    setClassId,
    className,
    setClassName,
    classNumeric,
    setClassNumeric,
    sectionId,
    setSectionId,
    sectionName,
    setSectionName,
    examId,
    setExamId,
    examName,
    setExamName,
    subjectId,
    setSubjectId,
    subjectName,
    setSubjectName,
    semester,
    setSemester,
    semesterName,
    setSemesterName,
    allBranch,
    allSession,
    allClass,
    allSection,
    allExam,
    allSubject,
    allSemester,
  };
};

export default useBranchSessionClassSectionExamList;

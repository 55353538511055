export const getShortName = (subject) => {
  if (subject === "English Language") {
    return "Eng Lang";
  } else if (subject === "Spelling and Dictation") {
    return "Spelling";
  } else if (subject === "English Literature") {
    return "Eng Lit";
  } else if (subject === "Mathematics") {
    return "Math";
  } else if (subject === "Additional Math") {
    return "Add Math";
  } else if (subject === "Business Studies") {
    return "Business";
  } else if (subject === "Bangladesh Studies") {
    return "B. Study";
  } else if (subject === "Computer Science") {
    return "Computer";
  } else {
    return subject;
  }
};

export const getGrade = (percentage) => {
  percentage = Math.round(percentage);
  if (percentage >= 95) {
    return "A+";
  }
  if (percentage < 95 && percentage >= 90) {
    return "A";
  }
  if (percentage < 90 && percentage >= 85) {
    return "B+";
  }
  if (percentage < 85 && percentage >= 80) {
    return "B";
  }
  if (percentage < 80 && percentage >= 75) {
    return "C+";
  }
  if (percentage < 75 && percentage >= 70) {
    return "C";
  }
  if (percentage < 70 && percentage >= 65) {
    return "D+";
  }
  if (percentage < 65 && percentage >= 60) {
    return "D";
  }
  if (percentage < 60) {
    return "U";
  }
};

export const getGrade11To12 = (percentage) => {
  percentage = Math.round(Number(percentage));
  if (percentage >= 90) {
    return "A+";
  }
  if (percentage < 90 && percentage >= 80) {
    return "A";
  }
  if (percentage < 80 && percentage >= 70) {
    return "B";
  }
  if (percentage < 70 && percentage >= 60) {
    return "C";
  }
  if (percentage < 60 && percentage >= 50) {
    return "D";
  }
  if (percentage < 50) {
    return "U";
  }
};

import { getExamMarkFromMarkDetails } from "./getExamMarkFromMarkDetails";
import { getGrade, getGrade11To12 } from "./getGrade";

const handleNaN = (value) => {
  if (isNaN(value)) {
    return 0;
  }
  return value;
};

// 3-7
const processMarksFirstSemester = (data = []) => {
  return data?.map((document) => {
    const grades = [];
    return {
      ...document,
      grade: grades,
      marks: document?.marks?.map((subject) => {
        // Class Test Mark
        const classTest = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "written"
        );
        const classTestFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "writtenFullMark"
        );

        // SBA Mark
        const sba = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "sba",
          "written"
        );
        const sbaFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "sba",
          "writtenFullMark"
        );

        // Assignment Mark
        const assignment = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "assignment",
          "written"
        );
        const assignmentFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "assignment",
          "writtenFullMark"
        );

        // Term Mark
        const term = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "written"
        );
        const termFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "writtenFullMark"
        );

        // Total Mark
        const total = classTest + sba + assignment + term;

        const grade = total > 0 ? getGrade(total) : "-";

        if (total) {
          grades.push(grade);
        }

        return {
          ...subject,
          classTest,
          classTestFullMark,
          sba,
          sbaFullMark,
          assignment,
          assignmentFullMark,
          term,
          termFullMark,
          total,
          grade,
        };
      }),
    };
  });
};
const processMarksSecondSemester = (data = []) => {
  return data?.map((document) => {
    const grades = [];
    return {
      ...document,
      grade: grades,
      marks: document?.marks?.map((subject) => {
        // First Semester Calculation
        // 1st Class Test Mark
        const classTest1stSem = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "written",
          "1stSemester"
        );
        const classTestFull1stSem = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "writtenFullMark",
          "1stSemester"
        );

        // 1st SBA Mark
        const sba1stSem = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "sba",
          "written",
          "1stSemester"
        );
        const sbaFull1stSem = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "sba",
          "writtenFullMark",
          "1stSemester"
        );

        // 1st Assignment Mark
        const assignment1stSem = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "assignment",
          "written",
          "1stSemester"
        );
        const assignmentFull1stSem = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "assignment",
          "writtenFullMark",
          "1stSemester"
        );

        // 1st Term Mark
        const term1stSem = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "written",
          "1stSemester"
        );
        const termFull1stSem = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "writtenFullMark",
          "1stSemester"
        );

        // Second Semester Calculation
        // Class Test Mark
        const classTest = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "written",
          "2ndSemester"
        );
        const classTestFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "writtenFullMark",
          "2ndSemester"
        );

        // SBA Mark
        const sba = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "sba",
          "written",
          "2ndSemester"
        );
        const sbaFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "sba",
          "writtenFullMark",
          "2ndSemester"
        );

        // Assignment Mark
        const assignment = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "assignment",
          "written",
          "2ndSemester"
        );
        const assignmentFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "assignment",
          "writtenFullMark",
          "2ndSemester"
        );

        // Term Mark
        const term = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "written",
          "2ndSemester"
        );
        const termFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "writtenFullMark",
          "2ndSemester"
        );

        // Total Mark
        const total1stSem =
          classTest1stSem + sba1stSem + assignment1stSem + term1stSem;
        const total2ndSem = classTest + sba + assignment + term;

        const total = total1stSem + total2ndSem;

        const grade = total > 0 ? getGrade(total / 2) : "-";

        if (total) {
          grades.push(grade);
        }

        return {
          ...subject,
          classTest1stSem,
          classTest,
          classTestFull1stSem,
          classTestFullMark,
          sba1stSem,
          sba,
          sbaFull1stSem,
          sbaFullMark,
          assignment1stSem,
          assignment,
          assignmentFull1stSem,
          assignmentFullMark,
          term1stSem,
          term,
          termFull1stSem,
          termFullMark,
          total1stSem,
          total2ndSem,
          total,
          grade,
        };
      }),
    };
  });
};

// 8-9
const processMarksHalfYearly = (data = []) => {
  return data?.map((document) => {
    const grades = [];
    return {
      ...document,
      grade: grades,
      marks: document?.marks?.map((subject) => {
        // Class Test Mark
        const classTest = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1"
        );
        const classTestFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1FullMark"
        );

        // SBA Mark
        const sba = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "sba",
          "paper1"
        );
        const sbaFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "sba",
          "paper1FullMark"
        );

        // Assignment Mark
        const assignment = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "assignment",
          "paper1"
        );
        const assignmentFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "assignment",
          "paper1FullMark"
        );

        // Term Mark
        const term = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAll"
        );
        const termFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAllFullMark"
        );

        // Ratio Mark
        const radio40 = handleNaN(
          Math.round(
            ((classTest + sba + assignment) /
              (classTestFullMark + sbaFullMark + assignmentFullMark)) *
              40
          )
        );
        const radio60 = handleNaN(Math.round((term / termFullMark) * 60)) || 0;

        // Total Mark
        const total = radio40 + radio60;

        const grade = total > 0 ? getGrade(total) : "-";

        if (total) {
          grades.push(grade);
        }

        return {
          ...subject,
          classTest,
          classTestFullMark,
          sba,
          sbaFullMark,
          assignment,
          assignmentFullMark,
          term,
          termFullMark,
          radio40,
          radio60,
          total,
          grade,
        };
      }),
    };
  });
};
const processMarksYearly = (data = []) => {
  return data?.map((document) => {
    const grades = [];
    return {
      ...document,
      grade: grades,
      marks: document?.marks?.map((subject) => {
        // Half Yearly Calculation
        // Class Test Mark
        const classTestHalfYearly = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1"
        );
        const classTestFullMarkHalfYearly = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1FullMark"
        );

        // SBA Mark
        const sbaHalfYearly = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "sba",
          "paper1"
        );
        const sbaFullMarkHalfYearly = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "sba",
          "paper1FullMark"
        );

        // Assignment Mark
        const assignmentHalfYearly = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "assignment",
          "paper1"
        );
        const assignmentFullMarkHalfYearly = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "assignment",
          "paper1FullMark"
        );

        // Term Mark
        const termHalfYearly = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAll"
        );
        const termFullMarkHalfYearly = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAllFullMark"
        );

        // Ratio Mark
        const radio40HalfYearly = handleNaN(
          Math.round(
            ((classTestHalfYearly + sbaHalfYearly + assignmentHalfYearly) /
              (classTestFullMarkHalfYearly +
                sbaFullMarkHalfYearly +
                assignmentFullMarkHalfYearly)) *
              40
          )
        );
        const radio60HalfYearly =
          handleNaN(
            Math.round((termHalfYearly / termFullMarkHalfYearly) * 60)
          ) || 0;

        // Yearly Calculation
        // Class Test Mark
        const classTest = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1"
        );
        const classTestFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1FullMark"
        );

        // SBA Mark
        const sba = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "sba",
          "paper1"
        );
        const sbaFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "sba",
          "paper1FullMark"
        );

        // Assignment Mark
        const assignment = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "assignment",
          "paper1"
        );
        const assignmentFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "assignment",
          "paper1FullMark"
        );

        // Term Mark
        const term = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAll"
        );
        const termFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAllFullMark"
        );

        // Ratio Mark
        const radio40 = handleNaN(
          Math.round(
            ((classTest + sba + assignment) /
              (classTestFullMark + sbaFullMark + assignmentFullMark)) *
              40
          )
        );
        const radio60 = handleNaN(Math.round((term / termFullMark) * 60)) || 0;

        // Total Mark
        const totalHalfYearly = radio40HalfYearly + radio60HalfYearly;
        const totalYearly = radio40 + radio60;
        const total = totalHalfYearly + totalYearly;

        const grade = total > 0 ? getGrade(total / 2) : "-";

        if (total) {
          grades.push(grade);
        }

        return {
          ...subject,
          classTest,
          classTestFullMark,
          sba,
          sbaFullMark,
          assignment,
          assignmentFullMark,
          term,
          termFullMark,
          radio40,
          radio60,
          totalHalfYearly,
          totalYearly,
          total,
          grade,
        };
      }),
    };
  });
};

// 10
const processMarksPreCambridge10 = (data = []) => {
  return data?.map((document) => {
    const grades = [];
    return {
      ...document,
      grade: grades,
      // Term Paper1 Mark
      marks: document?.marks?.map((subject) => {
        const termPaper1 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper1"
        );
        const termPaper1FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper1FullMark"
        );
        const termPaper1Ratio100 =
          handleNaN(Math.round((termPaper1 / termPaper1FullMark) * 100)) || 0;

        // Term Paper2 Mark
        const termPaper2 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper2"
        );
        const termPaper2FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper2FullMark"
        );
        const termPaper2Ratio100 =
          handleNaN(Math.round((termPaper2 / termPaper2FullMark) * 100)) || 0;

        // Term Paper3 Mark
        const termPaper3 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper3"
        );
        const termPaper3FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper3FullMark"
        );
        const termPaper3Ratio100 =
          handleNaN(Math.round((termPaper3 / termPaper3FullMark) * 100)) || 0;

        // Term All Paper Mark
        const term = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAll"
        );
        const termFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAllFullMark"
        );

        // Ratio Mark
        const ratio100 =
          handleNaN(Math.round((term / termFullMark) * 100)) || 0;

        // Total Mark
        const total = ratio100;
        const grade = total > 0 ? getGrade(total) : "-";

        if (total) {
          grades.push(grade);
        }

        return {
          ...subject,
          paper1: termPaper1Ratio100,
          paper1FullMark: termPaper1FullMark,
          paper2: termPaper2Ratio100,
          paper2FullMark: termPaper2FullMark,
          paper3: termPaper3Ratio100,
          paper3FullMark: termPaper3FullMark,
          total,
          grade,
        };
      }),
    };
  });
};
const processMarksPreparation10 = (data = []) => {
  return data?.map((document) => {
    const grades = [];
    return {
      ...document,
      grade: grades,
      // Term Paper1 Mark
      marks: document?.marks?.map((subject) => {
        const termPaper1 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper1"
        );
        const termPaper1FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper1FullMark"
        );
        const termPaper1Ratio100 =
          handleNaN(Math.round((termPaper1 / termPaper1FullMark) * 100)) || 0;

        // Term Paper2 Mark
        const termPaper2 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper2"
        );
        const termPaper2FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper2FullMark"
        );
        const termPaper2Ratio100 =
          handleNaN(Math.round((termPaper2 / termPaper2FullMark) * 100)) || 0;

        // Term Paper3 Mark
        const termPaper3 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper3"
        );
        const termPaper3FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper3FullMark"
        );
        const termPaper3Ratio100 =
          handleNaN(Math.round((termPaper3 / termPaper3FullMark) * 100)) || 0;

        // Term All Paper Mark
        const term = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAll"
        );
        const termFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAllFullMark"
        );

        // Ratio Mark
        const ratio100 =
          handleNaN(Math.round((term / termFullMark) * 100)) || 0;

        // Total Mark
        const total = ratio100;
        const grade = total > 0 ? getGrade(total) : "-";

        if (total) {
          grades.push(grade);
        }

        return {
          ...subject,
          paper1: termPaper1Ratio100,
          paper1FullMark: termPaper1FullMark,
          paper2: termPaper2Ratio100,
          paper2FullMark: termPaper2FullMark,
          paper3: termPaper3Ratio100,
          paper3FullMark: termPaper3FullMark,
          total,
          grade,
        };
      }),
    };
  });
};
const processMarksPreQualification10 = (data = []) => {
  return data?.map((document) => {
    const grades = [];
    return {
      ...document,
      grade: grades,
      marks: document?.marks?.map((subject) => {
        const termPaper1 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper1"
        );
        const termPaper1FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper1FullMark"
        );
        const termPaper1Ratio100 =
          handleNaN(Math.round((termPaper1 / termPaper1FullMark) * 100)) || 0;

        // Term Paper2 Mark
        const termPaper2 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper2"
        );
        const termPaper2FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper2FullMark"
        );
        const termPaper2Ratio100 =
          handleNaN(Math.round((termPaper2 / termPaper2FullMark) * 100)) || 0;

        // Term Paper3 Mark
        const termPaper3 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper3"
        );
        const termPaper3FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper3FullMark"
        );
        const termPaper3Ratio100 =
          handleNaN(Math.round((termPaper3 / termPaper3FullMark) * 100)) || 0;

        // Class Test Mark
        const classTest = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1"
        );
        const classTestFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1FullMark"
        );
        const classTestRatio15 =
          handleNaN(Math.round((classTest / classTestFullMark) * 15)) || 0;

        // Term Mark
        const term = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAll"
        );
        const termFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAllFullMark"
        );
        const termRatio85 =
          handleNaN(Math.round((term / termFullMark) * 85)) || 0;

        // Total Mark
        const total = classTestRatio15 + termRatio85;

        const grade = total > 0 ? getGrade(total) : "-";

        if (total) {
          grades.push(grade);
        }

        return {
          ...subject,
          classTest: classTestRatio15,
          classTestFullMark,
          term: termRatio85,
          termFullMark,
          paper1: termPaper1Ratio100,
          paper1FullMark: termPaper1FullMark,
          paper2: termPaper2Ratio100,
          paper2FullMark: termPaper2FullMark,
          paper3: termPaper3Ratio100,
          paper3FullMark: termPaper3FullMark,
          total,
          grade,
        };
      }),
    };
  });
};
const processMarksQualification10 = (data = []) => {
  return data?.map((document) => {
    const grades = [];
    return {
      ...document,
      grade: grades,
      // Term Paper1 Mark
      marks: document?.marks?.map((subject) => {
        const termPaper1 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper1",
          "qualifying"
        );
        const termPaper1FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper1FullMark",
          "qualifying"
        );
        const termPaper1Ratio100 =
          handleNaN(Math.round((termPaper1 / termPaper1FullMark) * 100)) || 0;

        // Term Paper2 Mark
        const termPaper2 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper2",
          "qualifying"
        );
        const termPaper2FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper2FullMark",
          "qualifying"
        );
        const termPaper2Ratio100 =
          handleNaN(Math.round((termPaper2 / termPaper2FullMark) * 100)) || 0;

        // Term Paper3 Mark
        const termPaper3 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper3",
          "qualifying"
        );
        const termPaper3FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper3FullMark",
          "qualifying"
        );
        const termPaper3Ratio100 =
          handleNaN(Math.round((termPaper3 / termPaper3FullMark) * 100)) || 0;

        // Term All Paper Mark
        const term = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAll",
          "qualifying"
        );
        const termFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAllFullMark",
          "qualifying"
        );

        // Ratio Mark
        const ratio100 =
          handleNaN(Math.round((term / termFullMark) * 100)) || 0;

        // Total Mark
        const total = ratio100;
        const grade = total > 0 ? getGrade(total) : "-";

        if (total) {
          grades.push(grade);
        }

        return {
          ...subject,
          paper1: termPaper1Ratio100,
          paper1FullMark: termPaper1FullMark,
          paper2: termPaper2Ratio100,
          paper2FullMark: termPaper2FullMark,
          paper3: termPaper3Ratio100,
          paper3FullMark: termPaper3FullMark,
          total,
          grade,
        };
      }),
    };
  });
};

// 11-12
const processMarksInterim11To12 = (data = []) => {
  return data?.map((document) => {
    const grades = [];
    return {
      ...document,
      grade: grades,
      marks: document?.marks?.map((subject) => {
        // Class Test - 1 Mark
        const classTest1 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1",
          "interim",
          0
        );
        const classTest1FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1FullMark",
          "interim",
          0
        );
        const classTest1Ratio20 =
          handleNaN(Math.round((classTest1 / classTest1FullMark) * 20)) || 0;

        // Class Test - 1 Mark
        const classTest2 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1",
          "interim",
          1
        );

        const classTest2FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1FullMark",
          "interim",
          1
        );
        const classTest2Ratio20 =
          handleNaN(Math.round((classTest2 / classTest2FullMark) * 20)) || 0;

        // CP Mark
        const cp = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "cp",
          "paper1",
          "interim"
        );

        const cpFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "cp",
          "paper1FullMark",
          "interim"
        );
        const cpRatio10 = handleNaN(Math.round((cp / cpFullMark) * 10)) || 0;

        // Student Quality
        const studentQuality = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "studentQuality",
          "paper1",
          "interim"
        );
        const studentQualityRatio100 =
          handleNaN(Math.round((studentQuality / 100) * 100)) || 0;

        const total = classTest1Ratio20 + classTest2Ratio20 + cpRatio10;

        const grade = total > 0 ? getGrade11To12(total * 2) : "-";

        if (total) {
          grades.push(grade);
        }

        return {
          ...subject,
          classTest1: classTest1Ratio20,
          classTest1FullMark,
          classTest2: classTest2Ratio20,
          classTest2FullMark,
          cp: cpRatio10,
          cpFullMark,
          studentQuality: studentQualityRatio100,
          studentQualityFullMark: 100,
          total,
          grade,
        };
      }),
    };
  });
};
const processMarksPreCambridge11To12 = (data = []) => {
  return data?.map((document) => {
    const grades = [];
    return {
      ...document,
      grade: grades,
      // Term Paper1 Mark
      marks: document?.marks?.map((subject) => {
        const termPaper1 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper1"
        );
        const termPaper1FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper1FullMark"
        );
        const termPaper1Ratio100 =
          handleNaN(Math.round((termPaper1 / termPaper1FullMark) * 100)) || 0;

        // Term Paper2 Mark
        const termPaper2 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper2"
        );
        const termPaper2FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper2FullMark"
        );
        const termPaper2Ratio100 =
          handleNaN(Math.round((termPaper2 / termPaper2FullMark) * 100)) || 0;

        // Term Paper3 Mark
        const termPaper3 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper3"
        );
        const termPaper3FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper3FullMark"
        );
        const termPaper3Ratio100 =
          handleNaN(Math.round((termPaper3 / termPaper3FullMark) * 100)) || 0;

        // Term All Paper Mark
        const term = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAll"
        );
        const termFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAllFullMark"
        );

        // Ratio Mark
        const ratio100 =
          handleNaN(Math.round((term / termFullMark) * 100)) || 0;

        // Total Mark
        const total = ratio100;
        const grade = total > 0 ? getGrade11To12(total) : "-";

        if (total) {
          grades.push(grade);
        }

        return {
          ...subject,
          term,
          termFullMark,
          paper1: termPaper1,
          paper1FullMark: termPaper1FullMark,
          paper2: termPaper2,
          paper2FullMark: termPaper2FullMark,
          paper3: termPaper3,
          paper3FullMark: termPaper3FullMark,
          total,
          grade,
        };
      }),
    };
  });
};
const processMarksPreparation11To12 = (data = []) => {
  return data?.map((document) => {
    const grades = [];
    return {
      ...document,
      grade: grades,
      marks: document?.marks?.map((subject) => {
        const termPaper1 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper1"
        );
        const termPaper1FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper1FullMark"
        );
        const termPaper1Ratio100 =
          handleNaN(Math.round((termPaper1 / termPaper1FullMark) * 100)) || 0;

        // Term Paper2 Mark
        const termPaper2 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper2"
        );
        const termPaper2FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper2FullMark"
        );
        const termPaper2Ratio100 =
          handleNaN(Math.round((termPaper2 / termPaper2FullMark) * 100)) || 0;

        // Term Paper3 Mark
        const termPaper3 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper3"
        );
        const termPaper3FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper3FullMark"
        );
        const termPaper3Ratio100 =
          handleNaN(Math.round((termPaper3 / termPaper3FullMark) * 100)) || 0;

        // PBL Test Mark
        const pbl = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "pbl",
          "paper1"
        );
        const pblFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1FullMark"
        );
        const pblRatio10 = handleNaN(Math.round((pbl / pblFullMark) * 10)) || 0;

        // CP Test Mark
        const cp = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "cp",
          "paper1"
        );
        const cpFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "cp",
          "paper1FullMark"
        );
        const cpRatio10 = handleNaN(Math.round((cp / cpFullMark) * 10)) || 0;

        // Term Mark
        const term = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAll"
        );
        const termFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAllFullMark"
        );
        const termRatio100 =
          handleNaN(Math.round((term / termFullMark) * 100)) || 0;

        // Ratio Mark
        const ratio20 = pblRatio10 + cpRatio10;
        const ratio80 = handleNaN(Math.round((termRatio100 / 100) * 80)) || 0;

        // Total Mark
        const total = ratio20 + ratio80;

        const grade = total > 0 ? getGrade11To12(total) : "-";

        if (total) {
          grades.push(grade);
        }

        return {
          ...subject,
          pbl: pblRatio10,
          pblFullMark,
          cp: cpRatio10,
          cpFullMark,
          term: term,
          termFullMark,
          paper1: termPaper1,
          paper1FullMark: termPaper1FullMark,
          paper2: termPaper2,
          paper2FullMark: termPaper2FullMark,
          paper3: termPaper3,
          paper3FullMark: termPaper3FullMark,
          ratio20,
          ratio80,
          total,
          grade,
        };
      }),
    };
  });
};
const processMarksQualification11To12 = (data = []) => {
  return data?.map((document) => {
    const grades = [];
    return {
      ...document,
      grade: grades,
      marks: document?.marks?.map((subject) => {
        // Interim Calculation
        // Class Test Mark
        const classTestInterim = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1",
          "interim"
        );
        const classTestFullMarkInterim = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "classTest",
          "paper1FullMark",
          "interim"
        );
        const classTestRatio40Interim =
          handleNaN(
            Math.round((classTestInterim / classTestFullMarkInterim) * 40)
          ) || 0;

        // CP Mark
        const cpInterim = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "cp",
          "paper1",
          "interim"
        );

        const cpFullMarkInterim = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "cp",
          "paper1FullMark",
          "interim"
        );
        const cpRatio10Interim =
          handleNaN(Math.round((cpInterim / cpFullMarkInterim) * 10)) || 0;

        // Qualifying Calculation
        // Term Paper1 Mark
        const termPaper1 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper1",
          "qualifying"
        );
        const termPaper1FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper1FullMark",
          "qualifying"
        );
        const termPaper1Ratio100 =
          handleNaN(Math.round((termPaper1 / termPaper1FullMark) * 100)) || 0;

        // Term Paper2 Mark
        const termPaper2 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper2",
          "qualifying"
        );
        const termPaper2FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper2FullMark",
          "qualifying"
        );
        const termPaper2Ratio100 =
          handleNaN(Math.round((termPaper2 / termPaper2FullMark) * 100)) || 0;

        // Term Paper3 Mark
        const termPaper3 = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper3",
          "qualifying"
        );
        const termPaper3FullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paper3FullMark",
          "qualifying"
        );
        const termPaper3Ratio100 =
          handleNaN(Math.round((termPaper3 / termPaper3FullMark) * 100)) || 0;

        // Term Mark
        const term = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAll",
          "qualifying"
        );
        const termFullMark = getExamMarkFromMarkDetails(
          subject?.marksDetails || [],
          "term",
          "paperAllFullMark",
          "qualifying"
        );
        const termRatio100 =
          handleNaN(Math.round((term / termFullMark) * 100)) || 0;

        // Ratio Mark
        const ratio40 =
          handleNaN(
            Math.round(((classTestRatio40Interim + cpRatio10Interim) / 50) * 40)
          ) || 0;
        const ratio60 = handleNaN(Math.round((termRatio100 / 100) * 60)) || 0;

        // Total Mark
        const totalInterim = ratio40;
        const totalQualifying = ratio60;
        const total = ratio40 + ratio60;

        const grade = totalQualifying > 0 ? getGrade11To12(total) : "-";

        if (totalQualifying) {
          grades.push(grade);
        }

        return {
          ...subject,
          term: term,
          termFullMark,
          paper1: termPaper1,
          paper1FullMark: termPaper1FullMark,
          paper2: termPaper2,
          paper2FullMark: termPaper2FullMark,
          paper3: termPaper3,
          paper3FullMark: termPaper3FullMark,
          ratio40,
          ratio60,
          totalInterim,
          totalQualifying,
          total,
          grade,
        };
      }),
    };
  });
};

// Root
const processMarks = ({ data = [], semester, classNumeric }) => {
  if (classNumeric >= 3 && classNumeric <= 7) {
    if (semester === "1stSemester") {
      return processMarksFirstSemester(data);
    }
    if (semester === "2ndSemester") {
      return processMarksSecondSemester(data);
    }
  }
  if (classNumeric >= 8 && classNumeric <= 9) {
    if (semester === "half_yearly") {
      return processMarksHalfYearly(data);
    }
    if (semester === "yearly") {
      return processMarksYearly(data);
    }
  }
  if (classNumeric === 10) {
    if (semester === "pre_qualifying") {
      return processMarksPreQualification10(data);
    }
    if (semester === "qualifying") {
      return processMarksQualification10(data);
    }
    if (semester === "preparation") {
      return processMarksPreparation10(data);
    }
    if (semester === "pre_cambridge") {
      return processMarksPreCambridge10(data);
    }
  }
  if (classNumeric >= 11 && classNumeric <= 12) {
    if (semester === "interim") {
      return processMarksInterim11To12(data);
    }
    if (semester === "preparation") {
      return processMarksPreparation11To12(data);
    }
    if (semester === "pre_cambridge") {
      return processMarksPreCambridge11To12(data);
    }
    if (semester === "qualifying") {
      return processMarksQualification11To12(data);
    }
  }
};

export default processMarks;

import intLogo from "assets/img/logo.png";

const SheetHeader = () => {
  return (
    <div
      style={{ paddingTop: "8px", marginTop: "8px" }}
      className="text-center"
    >
      <img style={{ width: "120px", margin: "8px" }} src={intLogo} alt="logo" />
      <h4>St. Joseph International School</h4>
      <p style={{ fontSize: "16px", fontWeight: "bold" }}>REPORT CARD</p>
    </div>
  );
};

export default SheetHeader;

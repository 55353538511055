const handleNaN = (value) => {
  if (isNaN(value)) {
    return 0;
  }
  return value;
};

export const getExamMarkFromMarkDetails = (
  marksDetails = [],
  examType,
  targetField = "paper1",
  semester,
  markIndex
) => {
  const filterMarks = () =>
    marksDetails.filter(
      (item) =>
        !!item?.date &&
        !!item?.examType &&
        item?.examType === examType &&
        (semester === undefined || item?.semester === semester)
    );

  const getSum = (field) =>
    filterMarks()?.reduce(
      (sum, x) => sum + Math.round(parseFloat(handleNaN(x?.[field]) || 0)),
      0
    ) || 0;

  if (targetField === "paperAll" || targetField === "paperAllFullMark") {
    const paperFields =
      targetField === "paperAll"
        ? ["paper1", "paper2", "paper3"]
        : ["paper1FullMark", "paper2FullMark", "paper3FullMark"];

    return Math.round(
      paperFields.reduce((sum, field) => sum + getSum(field), 0)
    );
  }

  if (typeof markIndex === "number") {
    const sortedMarksDetails = filterMarks()
      .filter((item) => !!item?.date)
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    return Math.round(
      parseFloat(handleNaN(sortedMarksDetails?.[markIndex]?.[targetField])) || 0
    );
  }

  return getSum(targetField);
};

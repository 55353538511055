import SimpleHeader from "components/Headers/SimpleHeader";
import {
  defaultPage,
  defaultSearchTerm,
  defaultShowPerPage,
} from "constants/jsons/defaultTableHelper.json";
import useClassSemesters from "hooks/useClassSemesters";
import { getStudentInfoMarkSheet } from "network/apiMarkEntries/apiMarkEntries";
import { getStudentAsFilter } from "network/helpers/basedOnApis";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ReactToPrint from "react-to-print";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { HideLoader, ShowLoader } from "redux/loaderSlice";
import store from "redux/store";
import QualitySheetModal from "./QualitySheetModal";
// import MarkSheetModal from "./MarkSheetModal";
import { SelectOption } from "components/ui/SelectOption";
import useFetchBranches from "hooks/useFetchBranches";
import useFetchBranchSessions from "hooks/useFetchBranchSessions";
import useFetchClassSections from "hooks/useFetchClassSections";
import useFetchSessionClasses from "hooks/useFetchSessionClasses";
import { transformDataForSelectOptions } from "utils/transformDataForSelectOptions";

const { SearchBar } = Search;

const StudentListForQualities = () => {
  // pagination states
  const [page, setPage] = React.useState(defaultPage);
  const [showPerPage, setShowPerPage] = React.useState(defaultShowPerPage);
  const [searchTerm, setSearchTerm] = React.useState(defaultSearchTerm);
  const [totalSize, setTotalSize] = React.useState(10);

  //filter states
  const [branchName, setBranchName] = React.useState("");
  const [branchId, setBranchId] = React.useState("");

  const [sessionName, setSessionName] = React.useState("");
  const [sessionId, setSessionId] = React.useState("");

  const [className, setClassName] = React.useState("");
  const [classId, setClassId] = React.useState("");
  const [classNumeric, setClassNumeric] = React.useState("");

  const [sectionName, setSectionName] = React.useState("");
  const [sectionId, setSectionId] = React.useState("");

  const [semester, setSemester] = React.useState("");

  // selectors states
  const { branches } = useFetchBranches({ page, showPerPage, searchTerm });
  const { sessions } = useFetchBranchSessions({ branchId });
  const { classes } = useFetchSessionClasses({ sessionId });
  const { sections } = useFetchClassSections({ classId });
  const { semesters } = useClassSemesters({ numeric: Number(classNumeric) });

  // modal states
  const [markModal, setMarkModal] = React.useState(false);

  // result states
  const [filteredStudent, setFilteredStudent] = React.useState([]);
  const [studentInfo, setStudentInfo] = React.useState({});

  const componentRef = React.useRef(null);

  const pagination = paginationFactory({
    page,
    totalSize,
    showTotal: true,
    sizePerPage: showPerPage,
    alwaysShowAllBtns: true,
    withFirstAndLast: true,
    onPageChange: (page) => {
      setPage(page);
    },
    onSizePerPageChange: (_, size) => {
      setShowPerPage(size);
    },
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              value={currSizePerPage}
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value="all">all</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const user = JSON.parse(localStorage.getItem("user"));
  const currentSession = JSON.parse(localStorage.getItem("session"));

  React.useEffect(() => {
    if (user?.role !== "superAdmin") {
      setBranchId(user?.branch?._id);
      setBranchName(user?.branch?.branchName);
      setSessionName(currentSession?.name);
      setSessionId(currentSession?.id);
    }
  }, [user, currentSession]);

  const handleFilter = async () => {
    store.dispatch(ShowLoader());
    const studentData = await getStudentAsFilter(
      branchName,
      sessionName,
      branchId,
      sessionId,
      classId,
      sectionId
    );
    setFilteredStudent(studentData?.data);
    store.dispatch(HideLoader());
  };
  return (
    <div>
      <SimpleHeader name="Sheet" parentName="Result" />
      <Container className="mt--6" fluid>
        <Card className="p-3">
          <section>
            <CardTitle>
              <h3 className="mb-0">
                <i className="ni ni-active-40 text-orange" />
                <span
                  className="ml-2"
                  style={{ fontSize: "large", color: "orange" }}
                >
                  Select Ground
                </span>
                <hr />
              </h3>
            </CardTitle>
            {user?.role === "superAdmin" && (
              <Row>
                <Col md="6" sm="6" lg="6">
                  <SelectOption
                    label="Branch"
                    name="branch"
                    id="branch"
                    data={transformDataForSelectOptions({
                      data: branches,
                      name: "branchName",
                      id: "_id",
                    })}
                    onChange={(e) => {
                      const { name, id } = JSON.parse(e.target.value);
                      setBranchName(name);
                      setBranchId(id);
                    }}
                  />
                </Col>
                <Col md="6" sm="6" lg="6">
                  <SelectOption
                    label="Session"
                    name="session"
                    id="session"
                    data={transformDataForSelectOptions({
                      data: sessions,
                      name: "sessionName",
                      id: "_id",
                    })}
                    onChange={(e) => {
                      const { name, id } = JSON.parse(e.target.value);
                      setSessionName(name);
                      setSessionId(id);
                    }}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md="4" sm="4" lg="4">
                <SelectOption
                  label="Class"
                  name="class"
                  id="class"
                  data={transformDataForSelectOptions({
                    data: classes,
                    name: "className",
                    id: "_id",
                    numeric: "classNumeric",
                  })}
                  onChange={(e) => {
                    const { name, id, numeric } = JSON.parse(e.target.value);
                    setClassName(name);
                    setClassId(id);
                    setClassNumeric(numeric);
                  }}
                />
              </Col>
              <Col md="4" sm="4" lg="4">
                <SelectOption
                  label="Section"
                  name="section"
                  id="section"
                  data={transformDataForSelectOptions({
                    data: sections,
                    name: "sectionName",
                    id: "_id",
                  })}
                  onChange={(e) => {
                    const { name, id } = JSON.parse(e.target.value);
                    setSectionName(name);
                    setSectionId(id);
                  }}
                />
              </Col>
              <Col md="4" sm="4" lg="4">
                <SelectOption
                  label="Semester"
                  name="semester"
                  id="semester"
                  data={semesters}
                  onChange={(e) => {
                    setSemester(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <div className="text-right mt-3">
              <Button onClick={handleFilter} color="success">
                Filter
              </Button>
            </div>
          </section>
        </Card>
      </Container>
      <Container fluid>
        <Card>
          <CardBody>
            <ToolkitProvider
              data={filteredStudent}
              keyField="outlet"
              columns={[
                {
                  dataField: "_id",
                  text: "Student Name",
                  sort: true,
                  formatter: (cell, row) => {
                    return `${row?.firstName} ${row?.lastName}`;
                  },
                },
                {
                  dataField: "regNo",
                  text: "Register No",
                  sort: true,
                },
                {
                  dataField: "roll",
                  text: "Roll",
                  sort: true,
                },
                {
                  dataField: "guardianName",
                  text: "Guardian Name",
                  sort: true,
                },
                {
                  dataField: "_id",
                  text: "Action",
                  formatter: (cell) => {
                    const viewMarkSheet = async () => {
                      const studentInfo = await getStudentInfoMarkSheet(
                        branchName,
                        branchId,
                        sessionName,
                        sessionId,
                        classId,
                        sectionId,
                        cell
                      );
                      setStudentInfo(studentInfo[0]);
                      setMarkModal(true);
                    };
                    return (
                      <div>
                        <Button
                          onClick={() => viewMarkSheet()}
                          className="btn-icon btn-2"
                          color="primary"
                          type="button"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-tv-2" />
                          </span>
                        </Button>
                      </div>
                    );
                  },
                },
              ]}
              search
            >
              {(props) => (
                <div className="py-4 table-responsive">
                  <Container fluid>
                    <Row>
                      <Col xs={4} sm={4}>
                        <div>
                          <select
                            name="datatable-basic_length"
                            aria-controls="datatable-basic"
                            className="form-control form-control-sm w-25"
                            onChange={(e) => setShowPerPage(e.target.value)}
                          >
                            <option
                              selected={showPerPage === 10 && true}
                              value={10}
                            >
                              10
                            </option>
                            <option
                              selected={showPerPage === 25 && true}
                              value={25}
                            >
                              25
                            </option>
                            <option
                              selected={showPerPage === 50 && true}
                              value={50}
                            >
                              50
                            </option>
                            <option
                              selected={showPerPage === 100 && true}
                              value={100}
                            >
                              100
                            </option>
                            <option
                              selected={showPerPage === "all" && true}
                              value="all"
                            >
                              all
                            </option>
                          </select>
                        </div>
                      </Col>
                      <Col xs={4} sm={4}>
                        <ButtonGroup>
                          <Button
                            className="buttons-copy buttons-html5"
                            color="warning"
                            size="sm"
                            id="copy-tooltip"
                          >
                            <span>CSV</span>
                          </Button>
                          <ReactToPrint
                            trigger={() => (
                              <Button
                                color="warning"
                                size="sm"
                                className="buttons-copy buttons-html5"
                                id="print-tooltip"
                              >
                                Print
                              </Button>
                            )}
                            content={() => componentRef.current}
                          />
                        </ButtonGroup>
                        <UncontrolledTooltip
                          placement="top"
                          target="print-tooltip"
                        >
                          This will open a print page with the visible rows of
                          the table.
                        </UncontrolledTooltip>
                        <UncontrolledTooltip
                          placement="top"
                          target="copy-tooltip"
                        >
                          This will Download Outlet Setting As CSV.
                        </UncontrolledTooltip>
                      </Col>
                      <Col xs={4} sm={4}>
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 pb-1 float-right"
                        >
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <BootstrapTable
                    remote
                    ref={componentRef}
                    {...props.baseProps}
                    bootstrap4={true}
                    pagination={pagination}
                    bordered={true}
                    hover={true}
                    id="react-bs-table"
                    noDataIndication={
                      <div
                        className="text-center font-weight-bold"
                        style={{ color: "red", fontWeight: "bold" }}
                      >
                        <p>
                          {branchName === ""
                            ? "Please Select Ground"
                            : "Please Wait ....."}
                        </p>
                      </div>
                    }
                    onTableChange={(type, { searchText }) => {
                      setSearchTerm(searchText);
                    }}
                  />
                </div>
              )}
            </ToolkitProvider>
          </CardBody>
        </Card>
      </Container>
      <QualitySheetModal
        modalOpen={markModal}
        setModalOpen={setMarkModal}
        semester={semester}
        classNumeric={classNumeric}
        studentInfo={studentInfo}
      />
    </div>
  );
};

export default StudentListForQualities;

import moment from "moment";

const SheetStudentInfo11To12 = ({ studentInfo, semesterName }) => {
  const {
    session,
    class: classDetails,
    section,
    regNo,
    roll,
    firstName,
    lastName,
    dob,
    gender,
  } = studentInfo;
  return (
    <div className="part1">
      <div className="std_info_grading">
        <div className="std_info">
          <h4>
            <u>Student's Information</u>
          </h4>
          <div>
            <div>Student's ID</div>
            <div>: {regNo}</div>
          </div>
          <div>
            <div>Student's Name</div>
            <div>
              : {firstName} {lastName}
            </div>
          </div>
          <div>
            <div>Date of Birth</div>
            <div>: {moment(new Date(dob)).format("DD-MMM-yyyy")}</div>
          </div>
          <div>
            <div>Gender</div>
            <div>: {gender === "female" ? "Female" : "Male"}</div>
          </div>
          <div>
            <div>Academic Session</div>
            <div>: {session?.sessionName}</div>
          </div>
          <div>
            <div>Roll</div>
            <div>: {roll}</div>
          </div>
          <div>
            <div>Standard</div>
            <div>
              : {classDetails?.className} ({classDetails?.classNumeric})
            </div>
          </div>
          <div>
            <div>Section</div>
            <div>: {section?.sectionName}</div>
          </div>
          <div>
            <div>Name of Examination</div>
            <div>
              : {semesterName} Examination {session?.sessionName}
            </div>
          </div>
        </div>
        <div className="grading">
          <p style={{ fontSize: "smaller" }}>Grade Boundaries</p>
          <table className="customTable">
            <thead>
              <tr>
                <th className="customThg" scope="col">
                  Marks
                </th>
                <th className="customThg" scope="col">
                  Grade
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="customTdg">90%</td>
                <td className="customTdg">A+</td>
              </tr>
              <tr>
                <td className="customTdg">80%</td>
                <td className="customTdg">A</td>
              </tr>
              <tr>
                <td className="customTdg">70%</td>
                <td className="customTdg">B</td>
              </tr>
              <tr>
                <td className="customTdg">60%</td>
                <td className="customTdg">C</td>
              </tr>
              <tr>
                <td className="customTdg">50%</td>
                <td className="customTdg">D</td>
              </tr>
              <tr>
                <td className="customTdg">&lt;50%</td>
                <td className="customTdg">U</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <h4 style={{ paddingLeft: "12px", marginBottom: 0 }}>
        Result In Details
      </h4>
    </div>
  );
};

export default SheetStudentInfo11To12;

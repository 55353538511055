import React from "react";
import ReactToPrint from "react-to-print";
import { Button, Modal } from "reactstrap";
import SingleSheet from "./sheets/SingleSheet";

const MarkSheetModal = ({ modalOpen, setModalOpen, ...props }) => {
  const componentRef = React.useRef(null);

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        className="modal-xl"
      >
        <div className="d-flex justify-content-between p-3">
          <div>
            <h5 className="modal-title" id="exampleModalScrollableTitle">
              Report Card
            </h5>
          </div>
          <div>
            <ReactToPrint
              trigger={() => (
                <Button
                  color="primary"
                  size="md"
                  className="buttons-copy buttons-html5 mr-auto ml-3"
                  id="print-tooltip"
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
        <div ref={componentRef} style={{ minWidth: "1131.1px" }}>
          <SingleSheet {...props} />
        </div>
        <div style={{ marginTop: "400px" }} className="modal-footer">
          <Button
            color="primary"
            outline
            onClick={() => setModalOpen(!modalOpen)}
            type="button"
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default MarkSheetModal;

import { getTabulationSemester } from "network/tabulation/apiTabulation";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { HideLoader, ShowLoader } from "redux/loaderSlice";
import processMarks from "utils/processMarks";

const useTabulationFilter = () => {
  const [results, setResults] = useState([]);
  const [isProcessComplete, setIsProcessComplete] = useState(false);
  const dispatch = useDispatch();

  const filterTabulation = async ({
    branchName,
    branchId,
    sessionName,
    sessionId,
    classId,
    classNumeric,
    sectionId,
    semester,
  }) => {
    try {
      await dispatch(ShowLoader());
      setIsProcessComplete(false);

      const data = await getTabulationSemester(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        sectionId,
        semester
      );

      console.log(data);

      const processedMarks = processMarks({
        data,
        semester,
        classNumeric,
      });

      // const processedMarks = processTabulation({
      //   tabulation: data,
      //   classNumeric,
      //   semester,
      // });

      const filteredResults = processedMarks?.map((document) => {
        return {
          ...document,
          total:
            document?.marks?.reduce((sum, subject) => sum + subject.total, 0) ||
            0,
        };
      });

      filteredResults?.sort((a, b) => b?.total - a?.total);

      setResults(filteredResults);
    } catch (error) {
      console.error(error);
    } finally {
      await dispatch(HideLoader());
      setIsProcessComplete(true);
    }
  };

  return { results, isProcessComplete, filterTabulation };
};

export default useTabulationFilter;

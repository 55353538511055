import baseApi from "../../api/baseApi";

export const getFilterWiseExamSchedule = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId,
  examId,
  subjectId
) => {
  try {
    const response = await baseApi.get(
      `/api/getFilterWiseExamSchedule?branchName=${branchName}&branchId=${branchId}&sessionName=${sessionName}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}&examId=${examId}&subjectId=${subjectId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data[0];
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getStudentForMarkEntry = async (
  branchName,
  sessionName,
  branchId,
  sessionId,
  classId,
  sectionId
) => {
  try {
    const response = await baseApi.get(
      `/api/getStudentsForMarkEntry?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const addMarkEntries = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId,
  examId,
  subjectId,
  marks
) => {
  const payload = {
    branchName,
    branchId,
    sessionName,
    sessionId,
    classId,
    sectionId,
    examId,
    subjectId,
    marks,
  };
  try {
    const response = await baseApi.post(`/api/addSubjectWiseMarks`, payload, {
      headers: { "Content-type": "application/json" },
    });
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getFilterWiseExamMarks = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId,
  examId,
  subjectId
) => {
  try {
    const response = await baseApi.get(
      `/api/getFilterWiseExamMarks?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}&examId=${examId}&subjectId=${subjectId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const deleteFilterWiseExamMarks = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId,
  examId,
  subjectId
) => {
  try {
    const response = await baseApi.get(
      `/api/deleteFilterWiseExamMarks?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}&examId=${examId}&subjectId=${subjectId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getStudentWiseExamMarks = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId,
  examId,
  subjectId,
  studentId
) => {
  try {
    const response = await baseApi.get(
      `/api/getStudentWiseExamMarks?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}&examId=${examId}&subjectId=${subjectId}&studentId=${studentId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const updateOneMarks = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId,
  examId,
  subjectId,
  editedMarks
) => {
  const payload = {
    branchName,
    branchId,
    sessionName,
    sessionId,
    classId,
    sectionId,
    examId,
    subjectId,
    editedMarks,
  };
  try {
    const response = await baseApi.put(`/api/updateOneMark`, payload, {
      headers: { "Content-type": "application/json" },
    });
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data?.message;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getResultSheet = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  studentId
) => {
  try {
    const response = await baseApi.get(
      `/api/getResultSheet?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&studentId=${studentId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getBulkResultSheet = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId
) => {
  try {
    const response = await baseApi.get(
      `/api/getBulkResultSheet?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getSubjectWiseHighestMarks = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId
) => {
  try {
    const response = await baseApi.get(
      `/api/getSubjectWiseHighestMarks?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getSubjectWiseFullMark = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId
) => {
  try {
    const response = await baseApi.get(
      `/api/getSubjectWiseFullMark?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getSubjectWisePaperMark = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId
) => {
  try {
    const response = await baseApi.get(
      `/api/getSubjectWisePaperMark?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getStudentInfoMarkSheet = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId,
  studentId
) => {
  try {
    const response = await baseApi.get(
      `/api/getStudentInfo?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}&studentId=${studentId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getBulkStudentInfoMarkSheet = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId
) => {
  try {
    const response = await baseApi.get(
      `/api/getBulkStudentInfo?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getStudentsWithMarksDetails = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId,
  semester
) => {
  try {
    const response = await baseApi.get(
      `/api/getStudentsWithMarksDetails?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}&semester=${semester}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const publishResult = async (markDetails, isPublished = true) => {
  try {
    const branchName = markDetails?.branch?.branchName;
    const branchId = markDetails?.branch?._id;
    const sessionName = markDetails?.session?.sessionName;
    const sessionId = markDetails?.session?._id;
    const classId = markDetails?.class?._id;
    const sectionId = markDetails?.section?._id;
    const examId = markDetails?.exam?._id;
    const subjectId = markDetails?.subject?._id;

    if (
      !branchName ||
      !branchId ||
      !sessionName ||
      !sessionId ||
      !classId ||
      !sectionId ||
      !examId ||
      !subjectId
    ) {
      return;
    }

    const payload = {
      isPublished: isPublished,
    };

    const response = await baseApi.post(
      `/api/publishResult?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}&examId=${examId}&subjectId=${subjectId}`,
      payload,
      {
        headers: { "Content-type": "application/json" },
      }
    );
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const resultsLockStatusUpdate = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId,
  examId,
  subjectId,
  isLocked
) => {
  try {
    if (
      !branchName ||
      !branchId ||
      !sessionName ||
      !sessionId ||
      !classId ||
      !sectionId ||
      !examId ||
      !subjectId
    ) {
      return;
    }

    const payload = {
      isLocked: isLocked,
    };

    const response = await baseApi.post(
      `/api/resultsLockStatusUpdate?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}&examId=${examId}&subjectId=${subjectId}`,
      payload,
      {
        headers: { "Content-type": "application/json" },
      }
    );
    if (response.statusText !== "OK") {
      return `"danger","Something Went Wrong","Session"`;
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

export const getResultsPublishedAndLockedStatus = async (
  branchName,
  branchId,
  sessionName,
  sessionId,
  classId,
  sectionId,
  examId,
  subjectId
) => {
  try {
    if (
      !branchName ||
      !branchId ||
      !sessionName ||
      !sessionId ||
      !classId ||
      !sectionId ||
      !examId ||
      !subjectId
    ) {
      return;
    }

    const response = await baseApi.get(
      `/api/getResultsPublishedAndLockedStatus?branchName=${branchName}&sessionName=${sessionName}&branchId=${branchId}&sessionId=${sessionId}&classId=${classId}&sectionId=${sectionId}&examId=${examId}&subjectId=${subjectId}`
    );
    if (response.statusText !== "OK") {
      return "Something Went Wrong";
    } else {
      return response?.data;
    }
  } catch (e) {
    console.log(e.response);
  }
};

import { Col, Row } from "reactstrap";
import SheetHeader from "../SheetHeader";
import SheetStudentInfo11To12 from "../SheetStudentInfo11To12";

const InterimSheet = ({
  result,
  highestMarks,
  filteredSubjects,
  classNumeric,
}) => {
  const { marks, qualities, total, ...studentInfo } = result;
  const quality = qualities[0] || {};
  const filteredMarks = marks.filter((item) =>
    filteredSubjects?.some((sub) => sub.value === item.subjectId)
  );

  return (
    <div style={{ margin: "8px" }} className="modal-body modal-body-custom">
      <SheetHeader />
      <div className="customResult">
        <SheetStudentInfo11To12
          studentInfo={studentInfo}
          semesterName={"Interim"}
        />
        <div>
          <table style={{ width: "100%", flex: "1" }} className="customTable">
            <thead>
              <tr>
                <th className="customTh">Subject</th>
                <th className="customTh">CT 1 (20)</th>
                <th className="customTh">CT 2 (20)</th>
                <th className="customTh">CP (10)</th>
                <th className="customTh">Total (50)</th>
                <th className="customTh">Attendance (%)</th>
                <th className="customTh">Grade</th>
              </tr>
            </thead>
            <tbody>
              {filteredMarks?.map((item, idx) => (
                <tr key={idx}>
                  <td className="customTd">{item?.subjectName}</td>
                  <td className="customTd">{item?.classTest1 || ""}</td>
                  <td className="customTd">{item?.classTest2 || ""}</td>
                  <td className="customTd">{item?.cp || ""}</td>
                  <td className="customTd">{item?.total || ""}</td>
                  <td className="customTd">{item?.studentQuality || ""}</td>
                  <td className="customTd">{item?.grade || ""}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="customTd font-weight-bold">Total Mark</td>
                <td colSpan={3} className="customTd"></td>
                <td className="customTd font-weight-bold">{total || ""}</td>
                <td className="customTd" colSpan={2}></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <Row className="mt-4">
        <Col md="12" className="pt-4">
          <h4 className="mt-4">Teacher's Comments</h4>
          <p className="mb-0 pb-0">
            (Teacher's comments on student's performance, behavior, and
            improvement areas.)
          </p>
        </Col>
        <Col md="12" className="my-4">
          ----------------
        </Col>
        <Col md="12" className="mb-4">
          <h4 className="mb-0 pb-0">Signatures,</h4>
        </Col>
        {/* <Col md="12" className="mb-4 d-flex align-items-end">
                      <h4 className="mb-0 pb-0">Teacher's Signature: </h4>
                      <span
                        style={{ width: "200px" }}
                        className="d-block mb-0 mx-2 pb-0 border-bottom border-dark"
                      />
                    </Col> */}
        <Col md="12" className="d-flex align-items-end">
          <h4 className="mb-0 pb-0">Parent's/Guardian's Signature: </h4>
          <span
            style={{ width: "200px" }}
            className="d-block mb-0 mx-2 pb-0 border-bottom border-dark"
          />
        </Col>
      </Row>
    </div>
  );
};

export default InterimSheet;

import SimpleHeader from "components/Headers/SimpleHeader";
import SelectBranchSessionClassSection from "components/Selectors/SelectBranchSessionClassSection";
import useBranchSessionClassSectionExamList from "hooks/useBranchSessionClassSectionExamList";
import useTabulationFilter from "hooks/useTabulationFilter";
import React from "react";
import ReactToPrint from "react-to-print";
import { Button, Card, CardTitle, Container } from "reactstrap";
import TabulationSheet from "../TabulationSheet";

const TabulationPreQualification = () => {
  const semester = "pre_qualifying";
  const componentRef = React.useRef(null);

  const branchSessionClassSectionStats = useBranchSessionClassSectionExamList([
    10,
  ]);
  const {
    branchId,
    branchName,
    sessionId,
    sessionName,
    classId,
    className,
    classNumeric,
    sectionId,
    sectionName,
  } = branchSessionClassSectionStats;

  const { results, isProcessComplete, filterTabulation } =
    useTabulationFilter();

  const handleFilter = () => {
    filterTabulation({
      branchName,
      branchId,
      sessionName,
      sessionId,
      classId,
      classNumeric,
      sectionId,
      semester,
    });
  };

  return (
    <div>
      <SimpleHeader name="Result" parentName="Tabulation" />
      <Container className="mt--6" fluid>
        <Card className="p-4">
          <CardTitle>
            <h3 className="mb-0">
              <i className="ni ni-active-40 text-orange" />
              <span
                className="ml-2"
                style={{ fontSize: "large", color: "orange" }}
              >
                Select Ground
              </span>
              <hr />
            </h3>
          </CardTitle>
          <div>
            <div>
              <SelectBranchSessionClassSection
                {...branchSessionClassSectionStats}
              />
            </div>
          </div>
          <div className="text-right">
            {isProcessComplete && results?.length > 0 && (
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="warning"
                    size="sm"
                    className="buttons-copy buttons-html5"
                    id="print-tooltip"
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            )}
            <Button size="sm" onClick={handleFilter} color="success">
              Filter
            </Button>
          </div>
        </Card>
      </Container>
      <Container fluid>
        {isProcessComplete && (
          <>
            {results?.length > 0 ? (
              <Card>
                <TabulationSheet
                  componentRef={componentRef}
                  className={className}
                  sectionName={sectionName}
                  results={results}
                  semester={semester}
                  semesterName={"Pre-Qualifying"}
                />
              </Card>
            ) : (
              <div className="text-center mt-4">
                <h3>No Data Found</h3>
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default TabulationPreQualification;

import { SelectOption } from "components/ui/SelectOption";
import { Col, Row } from "reactstrap";
import { transformDataForSelectOptions } from "utils/transformDataForSelectOptions";

const user = JSON.parse(localStorage.getItem("user"));

const SelectBranchSessionClassSection = ({
  branchId,
  setBranchId,
  branchName,
  setBranchName,
  sessionId,
  setSessionId,
  sessionName,
  setSessionName,
  classId,
  setClassId,
  className,
  setClassName,
  classNumeric,
  setClassNumeric,
  sectionId,
  setSectionId,
  sectionName,
  setSectionName,
  allBranch,
  allSession,
  allClass,
  allSection,
}) => {
  return (
    <div>
      {user?.role === "superAdmin" && (
        <Row>
          <Col md="6" sm="6" lg="6">
            <SelectOption
              label="Branch"
              name="branch"
              id="branch"
              data={transformDataForSelectOptions({
                data: allBranch,
                name: "branchName",
                id: "_id",
              })}
              onChange={(e) => {
                const { name, id } = JSON.parse(e.target.value);
                setBranchName(name);
                setBranchId(id);
              }}
            />
          </Col>
          <Col md="6" sm="6" lg="6">
            <SelectOption
              label="Session"
              name="session"
              id="session"
              data={transformDataForSelectOptions({
                data: allSession,
                name: "sessionName",
                id: "_id",
              })}
              onChange={(e) => {
                const { name, id } = JSON.parse(e.target.value);
                setSessionName(name);
                setSessionId(id);
              }}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col md="6" sm="6" lg="6">
          <SelectOption
            label="Class"
            name="class"
            id="class"
            data={transformDataForSelectOptions({
              data: allClass,
              name: "className",
              id: "_id",
              numeric: "classNumeric",
            })}
            onChange={(e) => {
              const { name, id, numeric } = JSON.parse(e.target.value);
              setClassName(name);
              setClassId(id);
              setClassNumeric(numeric);
            }}
          />
        </Col>
        <Col md="6" sm="6" lg="6">
          <SelectOption
            label="Section"
            name="section"
            id="section"
            data={transformDataForSelectOptions({
              data: allSection,
              name: "sectionName",
              id: "_id",
            })}
            onChange={(e) => {
              const { name, id } = JSON.parse(e.target.value);
              setSectionName(name);
              setSectionId(id);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SelectBranchSessionClassSection;

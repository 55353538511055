import SimpleHeader from "components/Headers/SimpleHeader";
import SelectBranchSessionClassSectionSemester from "components/Selectors/SelectBranchSessionClassSectionSemester";
import {
  defaultSearchTerm,
  defaultShowPerPage,
} from "constants/jsons/defaultTableHelper.json";
import useBranchSessionClassSectionExamList from "hooks/useBranchSessionClassSectionExamList";
import useMarkSheetFilter from "hooks/useMarkSheetFilter";
import { getSubjectWiseHighestMarks } from "network/apiMarkEntries/apiMarkEntries";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ReactToPrint from "react-to-print";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import MarkSheetModal from "./markSheetView/MarkSheetModal";
import MarkSheetModalBulk from "./markSheetView/MarkSheetModalBulk";

const animatedComponents = makeAnimated();

const customStyles = {
  control: (base) => ({
    ...base,
    height: 46,
    minHeight: 46,
    border: "1px solid lightGray",
  }),
};

const { SearchBar } = Search;

const MarkSheet = () => {
  // pagination states
  const [showPerPage, setShowPerPage] = React.useState(defaultShowPerPage);
  const [searchTerm, setSearchTerm] = React.useState(defaultSearchTerm);

  // result states
  const [studentResult, setStudentResult] = React.useState({});
  const [studentResultBulk, setStudentResultBulk] = React.useState([]);
  const [highestMarks, setHighestMarks] = React.useState([]);
  const [filteredSubjects, setFilteredSubjects] = React.useState([]);
  const componentRef = React.useRef(null);

  // modal states
  const [markModal, setMarkModal] = React.useState(false);
  const [markModalBulk, setMarkModalBulk] = React.useState(false);

  const branchSessionClassSectionStats = useBranchSessionClassSectionExamList();
  const {
    branchId,
    branchName,
    sessionId,
    sessionName,
    classId,
    className,
    classNumeric,
    sectionId,
    semester,
    allSubject,
  } = branchSessionClassSectionStats;

  const { results, isProcessComplete, filterMarkSheet } = useMarkSheetFilter();

  const handleHighestMarks = async () => {
    if (semester === "1stSemester" || semester === "2ndSemester") {
      const data = await getSubjectWiseHighestMarks(
        branchName,
        branchId,
        sessionName,
        sessionId,
        classId,
        sectionId
      );
      setHighestMarks(data);
    }
  };

  const handleFilter = () => {
    filterMarkSheet({
      branchName,
      branchId,
      sessionName,
      sessionId,
      classId,
      classNumeric,
      sectionId,
      semester,
    });
    handleHighestMarks();
  };

  const viewMarkSheet = async (data = {}) => {
    setStudentResult(data);
    setMarkModal(true);
  };

  const viewBulkMarkSheet = () => {
    setStudentResultBulk(results);
    setMarkModalBulk(true);
  };

  return (
    <div>
      <SimpleHeader name="Sheet" parentName="Result" />
      <Container className="mt--6" fluid>
        <Card className="p-3">
          <section>
            <CardTitle>
              <h3 className="mb-0">
                <i className="ni ni-active-40 text-orange" />
                <span
                  className="ml-2"
                  style={{ fontSize: "large", color: "orange" }}
                >
                  Select Ground
                </span>
                <hr />
              </h3>
            </CardTitle>
            <SelectBranchSessionClassSectionSemester
              {...branchSessionClassSectionStats}
            />
            {classNumeric && Number(classNumeric) >= 7 && (
              <Row>
                <Col md="12" lg="12" sm="12">
                  <Select
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    className="basic-single"
                    classNamePrefix="select"
                    isMulti
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    styles={customStyles}
                    onInputChange={(e) => setSearchTerm(e)}
                    value={filteredSubjects}
                    onChange={(e) => setFilteredSubjects(e)}
                    placeholder={
                      <div style={{ color: "", fontSize: "14px" }}>
                        Select Subjects ...
                      </div>
                    }
                    name="color"
                    options={allSubject}
                  />
                </Col>
              </Row>
            )}
            <div className="text-right mt-3">
              <Button onClick={viewBulkMarkSheet} color="primary">
                Bulk Sheet
              </Button>
              <Button onClick={handleFilter} color="success">
                Filter
              </Button>
            </div>
          </section>
        </Card>
      </Container>
      <Container fluid>
        {isProcessComplete && (
          <>
            {results?.length > 0 ? (
              <Card>
                <CardBody>
                  <ToolkitProvider
                    data={results}
                    keyField="outlet"
                    columns={[
                      {
                        dataField: "_id",
                        text: "Student Name",
                        sort: true,
                        formatter: (cell, row) => {
                          return `${row?.firstName} ${row?.lastName}`;
                        },
                      },
                      {
                        dataField: "regNo",
                        text: "Register No",
                        sort: true,
                      },
                      {
                        dataField: "roll",
                        text: "Roll",
                        sort: true,
                      },
                      {
                        dataField: "guardianName",
                        text: "Guardian Name",
                        sort: true,
                      },
                      {
                        dataField: "_id",
                        text: "Action",
                        formatter: (cell, row) => {
                          return (
                            <div>
                              <Button
                                onClick={() => viewMarkSheet(row)}
                                className="btn-icon btn-2"
                                color="primary"
                                type="button"
                              >
                                <span className="btn-inner--icon">
                                  <i className="ni ni-tv-2" />
                                </span>
                              </Button>
                            </div>
                          );
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={4} sm={4}>
                              <div>
                                <select
                                  name="datatable-basic_length"
                                  aria-controls="datatable-basic"
                                  className="form-control form-control-sm w-25"
                                  onChange={(e) =>
                                    setShowPerPage(e.target.value)
                                  }
                                >
                                  <option
                                    selected={showPerPage === 10 && true}
                                    value={10}
                                  >
                                    10
                                  </option>
                                  <option
                                    selected={showPerPage === 25 && true}
                                    value={25}
                                  >
                                    25
                                  </option>
                                  <option
                                    selected={showPerPage === 50 && true}
                                    value={50}
                                  >
                                    50
                                  </option>
                                  <option
                                    selected={showPerPage === 100 && true}
                                    value={100}
                                  >
                                    100
                                  </option>
                                  <option
                                    selected={showPerPage === "all" && true}
                                    value="all"
                                  >
                                    all
                                  </option>
                                </select>
                              </div>
                            </Col>
                            <Col className="text-center" xs={4} sm={4}>
                              <ButtonGroup>
                                <Button
                                  className="buttons-copy buttons-html5"
                                  color="warning"
                                  size="sm"
                                  id="copy-tooltip"
                                >
                                  <span>CSV</span>
                                </Button>
                                <ReactToPrint
                                  trigger={() => (
                                    <Button
                                      color="warning"
                                      size="sm"
                                      className="buttons-copy buttons-html5"
                                      id="print-tooltip"
                                    >
                                      Print
                                    </Button>
                                  )}
                                  content={() => componentRef.current}
                                />
                              </ButtonGroup>
                              <UncontrolledTooltip
                                placement="top"
                                target="print-tooltip"
                              >
                                This will open a print page with the visible
                                rows of the table.
                              </UncontrolledTooltip>
                              <UncontrolledTooltip
                                placement="top"
                                target="copy-tooltip"
                              >
                                This will Download Outlet Setting As CSV.
                              </UncontrolledTooltip>
                            </Col>
                            <Col xs={4} sm={4}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1 float-right"
                              >
                                <label>
                                  Search:
                                  <SearchBar
                                    className="form-control-sm"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                          remote
                          ref={componentRef}
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={true}
                          hover={true}
                          id="react-bs-table"
                          noDataIndication={
                            <div
                              className="text-center font-weight-bold"
                              style={{ color: "red", fontWeight: "bold" }}
                            >
                              <p>No data indicated</p>
                            </div>
                          }
                          onTableChange={(type, { searchText }) => {
                            setSearchTerm(searchText);
                          }}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            ) : (
              <div className="text-center mt-4">
                <h3>No Data Found</h3>
              </div>
            )}
          </>
        )}
      </Container>
      <MarkSheetModal
        modalOpen={markModal}
        setModalOpen={setMarkModal}
        classNumeric={classNumeric}
        semester={semester}
        result={studentResult}
        highestMarks={highestMarks}
        filteredSubjects={filteredSubjects}
      />
      <MarkSheetModalBulk
        modalOpen={markModalBulk}
        setModalOpen={setMarkModalBulk}
        className={className}
        classNumeric={classNumeric}
        semester={semester}
        resultBulk={studentResultBulk}
        highestMarks={highestMarks}
        filteredSubjects={filteredSubjects}
      />
    </div>
  );
};

export default MarkSheet;

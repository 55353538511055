import principalSignature from "assets/img/partials/principal-signature.png";
import vicePrincipalSignature from "assets/img/partials/vice-principal-signature.png";
import { Col, Row } from "reactstrap";
import SheetHeader from "../SheetHeader";
import SheetStudentInfo from "../SheetStudentInfo";

const QualificationSheet = ({
  result,
  highestMarks,
  filteredSubjects,
  classNumeric,
}) => {
  const { marks, qualities, total, ...studentInfo } = result;
  const quality = qualities[0] || {};
  const filteredMarks = marks.filter((item) =>
    filteredSubjects?.some((sub) => sub.value === item.subjectId)
  );

  return (
    <div style={{ margin: "8px" }} className="modal-body modal-body-custom">
      <SheetHeader />
      <div className="customResult">
        <SheetStudentInfo
          studentInfo={studentInfo}
          semesterName={"Qualifying"}
        />
        <div>
          <table style={{ width: "100%", flex: "1" }} className="customTable">
            <thead>
              <tr>
                <th className="customTh" rowSpan={2}>
                  SUBJECT
                </th>
                <th className="customTh" colSpan={5}>
                  Qualifying Examination
                </th>
              </tr>
              <tr>
                <th className="customTh">Paper 1</th>
                <th className="customTh">Paper 2</th>
                <th className="customTh">Paper 3</th>
                <th className="customTh">Total (100%)</th>
                <th className="customTh">Grade</th>
              </tr>
            </thead>
            <tbody>
              {filteredMarks?.map((item, idx) => (
                <tr key={idx}>
                  <td className="customTd">{item?.subjectName}</td>
                  <td className="customTd">{item?.paper1 || ""}</td>
                  <td className="customTd">{item?.paper2 || ""}</td>
                  <td className="customTd">{item?.paper3 || ""}</td>
                  <td className="customTd">{item?.total || ""}</td>
                  <td className="customTd">{item?.grade || ""}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="customTd font-weight-bold">Total Mark</td>
                <td colSpan={3} className="customTd"></td>
                <td className="customTd font-weight-bold">{total || ""}</td>
                <td className="customTd"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-4">
        <table style={{ width: "90%" }} className="customTable">
          <thead>
            <tr>
              <th colSpan={4} className="customTh">
                Subjects and Paper wise Marks
              </th>
            </tr>
            <tr>
              <th className="customTh" scope="col">
                Subject
              </th>
              <th className="customTh" scope="col">
                Paper 1
              </th>
              <th className="customTh" scope="col">
                Paper 2
              </th>
              <th className="customTh" scope="col">
                Paper 3
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredMarks?.map((item) => (
              <tr key={item?.subjectName}>
                <td className="customTd">{item?.subjectName}</td>
                <td className="customTd">{item?.paper1FullMark || ""}</td>
                <td className="customTd">{item?.paper2FullMark || ""}</td>
                <td className="customTd">{item?.paper3FullMark || ""}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Row className="mt-5">
        <Col md="4" sm="4" lg="4" className="text-center">
          <img style={{ width: "90px" }} src={principalSignature} alt="" />
          <p>----------------</p>
          <h5>Principal</h5>
        </Col>
        <Col md="4" sm="4" lg="4" className="text-center mt-4">
          <p className="mt-2">----------------</p>
          <h5>Class Teacher</h5>
        </Col>
        <Col md="4" sm="4" lg="4" className="text-center">
          <img style={{ width: "90px" }} src={vicePrincipalSignature} alt="" />
          <p>----------------</p>
          <h5>Vice-Principal</h5>
        </Col>
      </Row>
    </div>
  );
};

export default QualificationSheet;

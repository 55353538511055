// 3 - 7
import FirstSemesterSheet from "./(3-7)/FirstSemesterSheet";
import SecondSemesterSheet from "./(3-7)/SecondSemesterSheet";

// 8 - 9
import HalfYearlySheet from "./(8-9)/HalfYearlySheet";
import YearlySheet from "./(8-9)/YearlySheet";

// 10
import PreCambridgeSheet from "./(10)/PreCambridgeSheet";
import PreparationSheet from "./(10)/PreparationSheet";
import PreQualificationSheet from "./(10)/PreQualificationSheet";
import QualificationSheet from "./(10)/QualificationSheet";

// 11 - 12
import InterimSheet11To12 from "./(11-12)/InterimSheet";
import PreCambridgeSheet11To12 from "./(11-12)/PreCambridgeSheet";
import PreparationSheet11To12 from "./(11-12)/PreparationSheet";
import QualificationSheet11To12 from "./(11-12)/QualificationSheet";

import "./style.css";

const SingleSheet = ({ style, ...props }) => {
  const { classNumeric, semester } = props;

  return (
    <div style={style} className="printable-content">
      {semester === "1stSemester" &&
        Number(classNumeric) >= 3 &&
        Number(classNumeric) <= 7 && (
          <>
            <FirstSemesterSheet {...props} />
          </>
        )}
      {semester === "2ndSemester" &&
        Number(classNumeric) >= 3 &&
        Number(classNumeric) <= 7 && (
          <>
            <SecondSemesterSheet {...props} />
          </>
        )}
      {semester === "half_yearly" &&
        Number(classNumeric) >= 8 &&
        Number(classNumeric) <= 9 && (
          <>
            <HalfYearlySheet {...props} />
          </>
        )}
      {semester === "yearly" &&
        Number(classNumeric) >= 8 &&
        Number(classNumeric) <= 9 && (
          <>
            <YearlySheet {...props} />
          </>
        )}
      {semester === "pre_qualifying" && Number(classNumeric) === 10 && (
        <>
          <PreQualificationSheet {...props} />
        </>
      )}
      {semester === "qualifying" && Number(classNumeric) === 10 && (
        <>
          <QualificationSheet {...props} />
        </>
      )}
      {semester === "preparation" && Number(classNumeric) === 10 && (
        <>
          <PreparationSheet {...props} />
        </>
      )}
      {semester === "pre_cambridge" && Number(classNumeric) === 10 && (
        <>
          <PreCambridgeSheet {...props} />
        </>
      )}
      {semester === "interim" &&
        Number(classNumeric) >= 11 &&
        Number(classNumeric) <= 12 && (
          <>
            <InterimSheet11To12 {...props} />
          </>
        )}
      {semester === "qualifying" &&
        Number(classNumeric) >= 11 &&
        Number(classNumeric) <= 12 && (
          <>
            <QualificationSheet11To12 {...props} />
          </>
        )}
      {semester === "preparation" &&
        Number(classNumeric) >= 11 &&
        Number(classNumeric) <= 12 && (
          <>
            <PreparationSheet11To12 {...props} />
          </>
        )}
      {semester === "pre_cambridge" &&
        Number(classNumeric) >= 11 &&
        Number(classNumeric) <= 12 && (
          <>
            <PreCambridgeSheet11To12 {...props} />
          </>
        )}
    </div>
  );
};

export default SingleSheet;

import intLogo from "assets/img/logo.png";
import React from "react";
import { Table } from "reactstrap";
import { getShortName } from "utils/getShortName";

function gradeLength(grades) {
  const uniqueGrades = Array.from(new Set(grades));
  const gradeLengths = uniqueGrades?.map((grade) => ({
    grade,
    length: grades.filter((g) => g === grade)?.length,
  }));
  return gradeLengths;
}

const SingleMarkResult = ({ markData }) => {
  const total = markData?.total || 0;
  const grade = markData?.grade || "-";
  return (
    <>
      <p
        style={{ fontSize: "16px", color: "black" }}
        className="mb-0 pb-0 font-weight-500"
      >
        {Math.round(total)}
      </p>

      <span className="mb-0 mt-0 text-center">
        <hr
          style={{ color: "black" }}
          className="w-25 mb-0 mt-0 divider font-weight-500"
        />
      </span>

      <p
        className="mb-0 font-weight-500"
        style={{ fontSize: "16px", color: "black" }}
      >
        {grade}
      </p>
    </>
  );
};

const TabulationSheet = ({
  componentRef,
  className,
  sectionName,
  results,
  semesterName,
}) => {
  const uniqueSubjects = [];

  let finalSubjects = results
    ?.map((x) => x?.marks)
    ?.flat()
    ?.filter((element) => {
      const isDuplicate = uniqueSubjects.includes(element.subjectId);

      if (!isDuplicate) {
        uniqueSubjects.push(element.subjectId);

        return true;
      }
      return false;
    });

  return (
    <div>
      <div
        style={{
          paddingTop: "8px",
          paddingBottom: "8px",
          fontSize: "16px",
        }}
        ref={componentRef}
        className="modal-body printable-content"
      >
        <div className="text-center">
          <img style={{ width: "80px" }} src={intLogo} alt="logo" />
          <h4>St. Joseph International School</h4>
          <p style={{ fontSize: "12px", fontWeight: "bold" }}>
            Tabulation Sheet, {semesterName} Examination
          </p>
          <p style={{ fontSize: "12px", fontWeight: "bold" }}>
            Class: {className} {sectionName}
          </p>
        </div>
        <div className="">
          <div style={{ display: "flex" }}>
            <Table bordered responsive size="sm" className="">
              <thead>
                <tr>
                  <th
                    style={{ fontSize: "16px" }}
                    className="text-center align-middle font-weight-bold customTd"
                  >
                    Roll
                  </th>
                  <th
                    style={{ fontSize: "16px" }}
                    className="text-center align-middle font-weight-bold customTd"
                  >
                    Student Name
                  </th>
                  {finalSubjects?.map((s) => (
                    <th
                      style={{ maxHeight: "16px" }}
                      key={s?.subjectId}
                      className="text-center align-middle font-weight-bold customTd"
                    >
                      <p
                        className="font-weight-bold mb-0 pb-0"
                        style={{ fontSize: "16px" }}
                      >
                        {getShortName(s?.subjectName)}
                      </p>
                      <span className="mb-0 mt-0 text-center">
                        <hr
                          style={{ color: "black" }}
                          className="w-25 mb-0 mt-0 divider font-weight-500"
                        />
                      </span>
                      <p
                        className="font-weight-bold mb-0"
                        style={{ fontSize: "16px" }}
                      >
                        Grade
                      </p>
                    </th>
                  ))}

                  <th
                    style={{ fontSize: "16px" }}
                    className="text-center align-middle font-weight-bold customTd"
                  >
                    Total
                  </th>
                  <th
                    style={{ fontSize: "16px" }}
                    className="text-center align-middle font-weight-bold customTd"
                  >
                    Grade Count
                  </th>
                  <th
                    style={{ fontSize: "16px" }}
                    className="text-center align-middle font-weight-bold customTd"
                  >
                    Position
                  </th>
                </tr>
              </thead>
              <tbody>
                {results?.map((x, i) => (
                  <tr key={x?._id}>
                    <td
                      className="text-center align-middle customTd"
                      style={{ fontSize: "16px" }}
                    >
                      {x?.roll}
                    </td>
                    <td
                      className="text-center align-middle customTd"
                      style={{ fontSize: "16px" }}
                    >
                      {x?.firstName} {x?.lastName}
                    </td>
                    {finalSubjects?.map((s) => (
                      <td
                        key={s?.subjectId}
                        style={{ fontSize: "16px" }}
                        className="text-center align-middle customTd"
                      >
                        {x?.marks?.find(
                          (sub) => s?.subjectId === sub?.subjectId
                        )?.total ? (
                          <SingleMarkResult
                            markData={x?.marks?.find(
                              (sub) => s?.subjectId === sub?.subjectId
                            )}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                    ))}
                    <td
                      className="text-center align-middle customTd"
                      style={{ fontSize: "16px" }}
                    >
                      {Math.round(x?.total)}
                    </td>
                    <td
                      className="text-center align-middle customTd"
                      style={{ fontSize: "16px" }}
                    >
                      <span>
                        {gradeLength(x?.grade)?.map(
                          ({ grade, length }, index) => (
                            <React.Fragment key={grade}>
                              {grade}: {length}
                              {index !== gradeLength(x?.grade)?.length - 1
                                ? ", "
                                : ""}
                            </React.Fragment>
                          )
                        )}
                      </span>
                    </td>
                    <td
                      className="text-center align-middle customTd"
                      style={{ fontSize: "16px" }}
                    >
                      {i + 1}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <style jsx>{`
          @media print {
            @page {
              size: 1754px 1240px; /* At 150 DPI/PPI */
              margin: 0;
            }

            .printable-content {
              width: 100%;
              height: 100%;
            }

            .page-break {
              page-break-before: always;
            }
          }
        `}</style>
      </div>
    </div>
  );
};

export default TabulationSheet;
